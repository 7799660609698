import 'bootstrap';
import 'slick-carousel';
import 'readmore-js';

(function ($) {
	// On Dom Ready
	$(function () {
		// Toggle mobile menu
		$('.menu-open').on('click', function (e) {
			e.preventDefault();
			$('.mobile-menu-wrapper').fadeIn();
			$('.mobile-menu-wrapper').addClass('active');
		});
		$('.menu-close').on('click', function (e) {
			e.preventDefault();
			$('.mobile-menu-wrapper').removeClass('active');
			$('.mobile-menu-wrapper').fadeOut();
		});

		// Toggle mobile menu submenu
		$('.mobile-menu-wrapper .wrapper ul.mobile-menu > li.shop-link > a').on(
			'click',
			function (e) {
				e.preventDefault();
				$(this).parent().find('.categories-menu').slideToggle();
				$(this).toggleClass('active');
			}
		);
		$(
			'.mobile-menu-wrapper .wrapper ul.mobile-menu > li ul.categories-menu > li > a'
		).on('click', function (e) {
			e.preventDefault();
			$(this).parent().find('.products-menu').slideToggle();
			$(this).toggleClass('active');
		});

		// Toggle filters menu
		$('.filters.filters-mobile a.the-filter').on('click', function (e) {
			e.preventDefault();
			$('.diet-types-mobile').fadeIn();
			$('.diet-types-mobile').addClass('active');
		});
		$('.filters-close, .diet-types-mobile .wrapper a.the-title').on(
			'click',
			function (e) {
				e.preventDefault();
				$('.diet-types-mobile').removeClass('active');
				$('.diet-types-mobile').fadeOut();
			}
		);

		// Apply css class to top menu
		$('header .the-header ul.main-menu > li').each(function (index, element) {
			if ($(element).hasClass('current-menu-item')) {
				$('header .the-header ul.main-menu').addClass('active');
			}
		});

		// Home Banner Carousel
		$('.home-banner .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: true,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			rows: 0,
			lazyLoad: 'ondemand',
		});

		// About page banner Play/Pause
		$('#btnPlayPause').on('click', function (e) {
			e.preventDefault();
			$(this).toggleClass('active');

			if ($('.video-element')[0].paused == false) {
				$('.video-element')[0].pause();
			} else {
				$('.video-element')[0].play();
			}
		});
		$('#btnMute').on('click', function (e) {
			e.preventDefault();
			$(this).toggleClass('active');

			if ($('.video-element').prop('muted')) {
				$('.video-element').prop('muted', false);
			} else {
				$('.video-element').prop('muted', true);
			}
		});

		// pause video on mobile

		if (
			$(window).width() <= 576 &&
			$('body').hasClass('page-template-template-about-us') == true
		) {
			$('#btnPlayPause').removeClass('active');
			$('.video-element')[0].pause();

			$('#btnMute').addClass('active');
			$('.video-element').prop('muted', false);
		}

		// Most Popular Carousel
		$('.most-popular .the-carousel, .related-products .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: true,
			infinite: true,
			speed: 700,
			slidesToShow: 4,
			slidesToScroll: 4,
			adaptiveHeight: false,
			rows: 0,
			prevArrow:
				'<button type="button" class="slick-prev"><span class="icon-left-open"></span></button>',
			nextArrow:
				'<button type="button" class="slick-next"><span class="icon-right-open"></span></button>',
			lazyLoad: 'ondemand',
			responsive: [
				{
					breakpoint: 994,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 579,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		// From farm to bowl Carousel
		$('.box-carousel.the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: true,
			arrows: true,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: false,
			rows: 0,
			prevArrow:
				'<button type="button" class="slick-prev"><span class="icon-left-open"></span></button>',
			nextArrow:
				'<button type="button" class="slick-next"><span class="icon-right-open"></span></button>',
			lazyLoad: 'ondemand',
			responsive: [
				{
					breakpoint: 994,
					settings: {
						arrows: false,
					},
				},
			],
		});

		// Sustainable Milling Carousel
		$('.sustainable-milling .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: true,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: false,
			rows: 0,
			lazyLoad: 'ondemand',
		});

		// Over 300 Years - Carousel
		$('.over-300-years .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: true,
			infinite: true,
			speed: 700,
			slidesToShow: 4,
			slidesToScroll: 4,
			adaptiveHeight: true,
			rows: 0,
			prevArrow:
				'<button type="button" class="slick-prev"><span class="triangle-left"></span></button>',
			nextArrow:
				'<button type="button" class="slick-next"><span class="triangle-right"></span></button>',
			lazyLoad: 'ondemand',
			responsive: [
				{
					breakpoint: 994,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					},
				},
				{
					breakpoint: 579,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		// Login page carousel
		$('.signup-login-wrapper .left-side .the-overlay .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: true,
			fade: true,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: false,
			rows: 0,
			lazyLoad: 'ondemand',
		});

		// Toggle mega menu
		$('header .the-header ul.main-menu > li.shop-link > a, .mega-menu').on({
			mouseenter: function () {
				openMegaMenu();
			},
			mouseleave: function () {
				closeMegaMenu();
			},
		});

		$('.body-wrapper, header').on('click', function (e) {
			if (!$(e.target).parent().is('.shop-link')) {
				closeMegaMenu();
			}
		});

		function openMegaMenu() {
			$('.mega-menu').addClass('active');
			$('header .the-header ul.main-menu').addClass('active');
			$('header .the-header ul.main-menu > li.shop-link').addClass('active');
			$('body').addClass('noscroll');
		}

		function closeMegaMenu() {
			$('.mega-menu').removeClass('active');
			$('header .the-header ul.main-menu').removeClass('active');
			$('header .the-header ul.main-menu > li.shop-link').removeClass('active');
			$('body').removeClass('noscroll');
		}

		// Hide featured product box when megamenu is hovered
		$('.mega-menu ul.categories-menu').on({
			mouseenter: function () {
				$('.mega-menu .featured-product').removeClass('d-flex');
				$('.mega-menu .featured-product').addClass('d-none');
			},
			mouseleave: function () {
				$('.mega-menu .featured-product').addClass('d-flex');
				$('.mega-menu .featured-product').removeClass('d-none');
			},
		});

		// Toggle more recipe categories
		$('.show-more-recipes').on('click', function (e) {
			e.preventDefault();
			$('.more-recipe-categories-wrapper').toggleClass('active');
			if ($('.more-recipe-categories-wrapper').hasClass('active')) {
				$('.more-recipe-categories-wrapper').slideDown();
				$(this).html('Show Less  <span class="icon-right"></span>');
			} else {
				$('.more-recipe-categories-wrapper').slideUp();
				$(this).html('Show More  <span class="icon-right"></span>');
			}
		});

		// Filter Categories by Diet Type
		$(
			'.category-filters .js-filters .the-filter, .diet-types-mobile .the-filter.hide-on-click'
		).on('click', function (e) {
			e.preventDefault();
			if ($(this).hasClass('active')) {
				$(this).removeClass('active');
				$('.products-list .products .product').fadeIn();
			} else {
				let diet_type = $(this).data('diet-type');
				let data_exists = $(this).data('exists');

				$('.products-list .products .product').fadeOut();
				$('.products-list .products .product.' + diet_type).fadeIn();
				$('.category-filters .the-filter').removeClass('active');
				$('.diet-types-mobile .the-filter').removeClass('active');
				$(this).addClass('active');

				console.log(data_exists);
				$('.content .products-list .not-found').remove();
				if (data_exists !== 1) {
					$('.content .products-list').append(
						'<div class="not-found text-center" style="font-size:20px; ">No products available</div>'
					);
				} else {
					$('.content .products-list .not-found').remove();
				}
			}

			if ($(this).hasClass('hide-on-click')) {
				$('.diet-types-mobile').removeClass('active');
				$('.diet-types-mobile').fadeOut();
			}
		});

		//============== Woocommerce related ========================

		// Quantity "plus" and "minus" buttons
		/* $(document.body).on('click', '.plus, .minus', function () {
			var $qty = $(this).closest('.quantity').find('.qty'),
				currentVal = parseFloat($qty.val()),
				max = parseFloat($qty.attr('max')),
				min = parseFloat($qty.attr('min')),
				step = $qty.attr('step');

			// Format values
			if (!currentVal || currentVal === '' || currentVal === 'NaN')
				currentVal = 0;
			if (max === '' || max === 'NaN') max = '';
			if (min === '' || min === 'NaN') min = 0;
			if (
				step === 'any' ||
				step === '' ||
				step === undefined ||
				parseFloat(step) === 'NaN'
			)
				step = 1;

			// Change the value
			if ($(this).is('.plus')) {
				if (max && currentVal >= max) {
					$qty.val(max);
				} else {
					$qty.val((currentVal + parseFloat(step)).toFixed(step.getDecimals()));
				}
			} else {
				if (min && currentVal <= min) {
					$qty.val(min);
				} else if (currentVal > 0) {
					$qty.val((currentVal - parseFloat(step)).toFixed(step.getDecimals()));
				}
			}

			if ($qty.val() == currentVal && currentVal == max) {
				alert('No more stock available, please call us');
			}

			// Trigger change event
			$qty.trigger('change');
			var qty_val = $qty.val();
			var qty_name = $qty.attr('name');
			$(document)
				.find("input[name='" + qty_name + "']")
				.val(qty_val);
		}); */

		// Change add to cart button text after added to cart from loop
		$(document.body).on('adding_to_cart', function ($button, data) {
			var the_button = $(data[0]);
			the_button.attr('disabled', 'true');
			the_button.blur();
		});

		$(document.body).on(
			'added_to_cart',
			function (event, fragments, cart_hash, $button) {
				let button_text = $($button[0]).html();
				var the_button = $($button[0]);
				the_button.html('Added');
				setTimeout(() => {
					the_button.html(button_text);
					the_button.removeClass('added');
					the_button.removeAttr('disabled');
				}, 3000);

				show_cart();
			}
		);

		// Ajax add to cart on the product page
		var $warp_fragment_refresh = {
			url: wc_cart_fragments_params.wc_ajax_url
				.toString()
				.replace('%%endpoint%%', 'get_refreshed_fragments'),
			type: 'POST',
			success: function (data) {
				if (data && data.fragments) {
					$.each(data.fragments, function (key, value) {
						$(key).replaceWith(value);
					});

					$(document.body).trigger('wc_fragments_refreshed');
				}
			},
		};

		$('.entry-summary form.cart').on('submit', function (e) {
			e.preventDefault();

			var button_text = $('.entry-summary').find('form.cart .button').html();
			$('.entry-summary').find('form.cart .button').html('Adding...');

			var product_url = window.location,
				form = $(this);

			$.post(
				product_url,
				form.serialize() + '&_wp_http_referer=' + product_url,
				function (result) {
					//console.log(result);
					var cart_dropdown = $('.widget_shopping_cart', result);

					// update dropdown cart
					$('.widget_shopping_cart').replaceWith(cart_dropdown);

					// update fragments
					$.ajax($warp_fragment_refresh);

					// show error if exists
					if (
						$(result).find('.woocommerce-notices-wrapper ul.woocommerce-error')
							.length > 0
					) {
						$('.woocommerce div.product .product-main .notice-wrapper').html(
							$(result).find('.woocommerce-notices-wrapper')
						);
						$('.entry-summary').find('form.cart .button').html(button_text);
					} else {
						//$('.entry-summary').unblock();
						$('.woocommerce div.product .product-main .notice-wrapper').html(
							''
						);
						$('.entry-summary').find('form.cart .button').html('Added');

						show_cart();
					}
				}
			);
		});

		function show_cart() {
			$('.slidein-cart').fadeIn();
			$('.slidein-cart').addClass('active');
			$('body').addClass('hidescrollbars');
		}
		function hide_cart() {
			$('.slidein-cart').fadeOut();
			$('.slidein-cart').removeClass('active');
			$('body').removeClass('hidescrollbars');
		}

		// Toggle T&C
		$('.terms-page .tandc-box .the-title').on('click', function (e) {
			$(this).next().slideToggle();
		});

		// Toggle Where to buy sections
		$('.product-link').on('click', function (e) {
			e.preventDefault();
			$('ul.search-by li a').removeClass('active');
			$(this).addClass('active');

			$('#wtb-products-list').fadeIn();
			$('#wtb-shop-list').fadeOut();
		});
		$('.store-link').on('click', function (e) {
			e.preventDefault();
			$('ul.search-by li a').removeClass('active');
			$(this).addClass('active');

			$('#wtb-products-list').fadeOut();
			$('#wtb-shop-list').fadeIn();
		});

		// Toggle stores list on products
		$('.show-stores-wrapper .the-button.btn-lined').on('click', function (e) {
			e.preventDefault();
			$(this).next().slideToggle();
		});

		// Share post toggle
		$('#share-button').on('click', function (e) {
			e.preventDefault();
			$('.sharing-box').slideToggle();
		});

		// Ajax loadmore posts
		$('.amd_loadmore .the-button').on('click', function (e) {
			e.preventDefault();
			var button = $(this),
				data = {
					action: 'loadmore',
					query: amd_loadmore_params.posts, // that's how we get params from wp_localize_script() function
					page: amd_loadmore_params.current_page,
				};

			$.ajax({
				url: amd_loadmore_params.ajaxurl, // AJAX handler
				data: data,
				type: 'POST',
				beforeSend: function (xhr) {
					button.text('Loading...'); // change the button text, you can also add a preloader image
				},
				success: function (data) {
					if (data) {
						button.text('Load more');
						$('.posts-list').append(data); // insert new posts

						amd_loadmore_params.current_page++;

						if (
							amd_loadmore_params.current_page == amd_loadmore_params.max_page
						)
							button.remove(); // if last page, remove the button
					} else {
						button.remove(); // if no data, remove the button as well
					}
				},
			});
		});

		// Toggle recipe methods check/uncheck
		$('.the-page.single-recipe .method ul li').on('click', function (e) {
			$(this).toggleClass('checked');
		});

		// Toggle product collapsible details
		$(
			'.woocommerce div.product .product-collapsible-detaials .the-box .the-title'
		).on('click', function (e) {
			$(this).toggleClass('active');
			$(this).next().slideToggle();
		});

		// On variation change
		$('.single_variation_wrap').on(
			'show_variation',
			function (event, variation) {
				// Fired when the user selects all the required dropdowns / attributes
				// and a final variation is selected / shown
				var variation_price = $('.woocommerce-variation-price').html();

				if (variation_price !== '') {
					var price_range = $(
						'.woocommerce div.product .product-main .price-wrapper p.price'
					).html();
					$(
						'.woocommerce div.product .product-main .price-wrapper p.price'
					).html(variation_price);
				}
			}
		);

		// Toggle basket
		$(
			'header .the-header .cart-search-wrapper a.cart-link, header .the-header a.cart-link-mobile'
		).on('click', function (e) {
			e.preventDefault();
			show_cart();
		});
		$('.slidein-cart .the-cart .cart-close').on('click', function (e) {
			e.preventDefault();
			hide_cart();
		});
		//$('header .the-header .cart-search-wrapper a.cart-link').trigger('click');

		// Toggle collasible footer menu
		$('.footer-mobile .collapsible-blocks .the-block .the-title').on(
			'click',
			function (e) {
				e.preventDefault();
				$(this).toggleClass('active');
				$(this).next().slideToggle();
			}
		);

		// Toggle recipe details (ingredients, methods)
		$(
			'.the-page.single-recipe .recipe-content .ingredients h3, .the-page.single-recipe .recipe-content .method h3'
		).on('click', function (e) {
			e.preventDefault();
			$(this).toggleClass('active');
			$(this).next().slideToggle();
		});

		// Expand / Collapse category description
		//$('.category-header .category-description').readmore({
		$(
			'body.term-oatbran .category-header .category-description, body.term-oatmeal .category-header .category-description, body.term-squares .category-header .category-description, body.term-baking-range .category-header .category-description, body.term-oath .category-header .category-description'
		).readmore({
			speed: 75,
			moreLink: '<a href="#">More...</a>',
			lessLink: '<a href="#">Less...</a>',
		});

		// Move Coupon Form
		$('.checkout_coupon').show();
		var coupon = $('.checkout_coupon');
		$(coupon).insertAfter(
			'.shop_table.woocommerce-checkout-review-order-table'
		);
	});

	// update cart on quantity change in minicart
	var timeout;

	$('.woocommerce').on('change', '.slidein-cart .qty', function () {
		var item_hash = $(this)
			.attr('name')
			.replace(/cart\[([\w]+)\]\[qty\]/g, '$1');
		var item_quantity = $(this).val();
		var currentVal = parseFloat(item_quantity);
		$('.slidein-cart .the-cart').addClass('loading');

		function qty_cart() {
			$.ajax({
				type: 'POST',
				url: amd_loadmore_params.ajaxurl,
				data: {
					action: 'qty_cart',
					hash: item_hash,
					quantity: currentVal,
				},
				success: function (data) {
					$('.slidein-cart .widget_shopping_cart_content').html(data);
					$('.slidein-cart .the-cart').removeClass('loading');
				},
			});
		}

		qty_cart();
	});

	// After page loaded completely
	$(window).on('load', function () {});
})(jQuery);
